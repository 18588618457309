
	import Vue3autocounter from "vue3-autocounter";
	import { Options, prop, Vue } from "vue-class-component";
	import { CurrentBotsEntry } from "../expgenshared/CurrentBotsResponse";
	import { MessageType } from "../expgenshared/MessageTypes";
	import { BotActionResponse } from "../expgenshared/BotActionResponse";
	import { BotState } from "../expgenshared/BotState";
	import { FarmState } from "../expgenshared/FarmState";
	import { useRoute } from "vue-router";
	import { Watch } from "vue-property-decorator";
	import TimeAgo from "./TimeAgo.vue";

	import Swal from "sweetalert2";
	class Props {
		bot: CurrentBotsEntry = prop({
			required: true,
		});
		botState: BotState = prop({
			required: true,
		});
	}
	@Options({
		components: {
			TimeAgo,
			Vue3autocounter,
		},
		sockets: {
			getBotXpResponse(resp) {
				let [xp, uuid]: [number, string] = resp;
				if (uuid == this.bot.uuid) {
					this.xp = xp;
				}
			},
		},
	})
	export default class Status extends Vue.with(Props) {
		$socket!: any;
		sockets!: any;
		botState: BotState = null;
		xp: number = 0;
		startCounter: number = 0;
		checkXPInterval: any;

		get stateName() {
			return FarmState[this.botState.farmState];
		}

		get online() {
			return this.botState.farmState != FarmState.OFFLINE;
		}
		setStartCounter() {
			this.startCounter = this.xp;
		}

		beforeUnmount() {
			this.sockets.unsubscribe(MessageType.GET_XP_RESPONSE);
			clearInterval(this.checkXPInterval);
		}

		mounted() {
			this.getXp();
			this.checkXPInterval = setInterval(() => {
				this.getXp();
			}, 30000);
		}
		getXp(): void {
			this.$socket.emit(MessageType.GET_XP, this.bot.uuid);
		}
		killBot(): void {
			this.$socket.emit(MessageType.KILL_BOT, this.bot.uuid);
		}
		startBot(): void {
			this.$socket.emit(MessageType.START_BOT, this.bot.uuid);
			Swal.fire({
				toast: true,
				timer: 2000,
				icon: "success",
				title: "Started bot!",
				position: "top-right",
			});
		}
	}
