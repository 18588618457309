
	import { Getter } from "s-vuex-class";
	import { Options, Vue, prop } from "vue-class-component";
	import { BotState } from "../expgenshared/BotState";
	import { ConsoleMessage } from "../expgenshared/ConsoleMessage";
	import { ChatMessageRequest } from "../expgenshared/ChatMessageRequest";
	import { CurrentBotsEntry } from "../expgenshared/CurrentBotsResponse";
	import { MessageType } from "../expgenshared/MessageTypes";
	import Convert from "ansi-to-html";

	class Props {
		bot: CurrentBotsEntry = prop({
			required: true,
		});
		botState: BotState = prop({
			required: true,
		});
	}

	@Options({
		sockets: {
			newChatMessage(chatMessage: ConsoleMessage) {
				if (chatMessage.botId == this.bot.uuid)
					this.botState.messages.push(chatMessage);
			},
			worldUpdate(uuid: string) {
				if (uuid == this.bot.uuid) this.refreshLiveView();
			},
		},
	})
	export default class LiveView extends Vue.with(Props) {
		$socket!: any;
		sockets!: any;
		declare $refs: {
			liveViewFrame: HTMLInputElement;
		};
		showGraphic: boolean = false;
		consoleInput: string = "";
		//@ts-ignore
		viewUrl: string = process.env.VUE_APP_URL;
		sendMessage() {
			this.$socket.emit(MessageType.SEND_CHAT_MESSAGE, {
				botId: this.bot.uuid,
				message: this.consoleInput,
			} as ChatMessageRequest);
			this.consoleInput = "";
		}

		refreshLiveView() {
			this.$refs.liveViewFrame.src += "";
		}

		convertMessage(msg: string) {
			let convert: Convert = new Convert();
			return convert.toHtml(msg);
		}

		unmounted() {
			this.sockets.unsubscribe(MessageType.CHAT_MESSAGE);
			this.sockets.unsubscribe(MessageType.WORLD_UPDATE);
		}
	}
