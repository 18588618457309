
	import { Options, Vue } from "vue-class-component";

	import LiveView from "../components/LiveView.vue";
	import Status from "../components/Status.vue";
	import { CurrentBotsEntry } from "../expgenshared/CurrentBotsResponse";
	import { Getter } from "s-vuex-class";
	import { useRoute } from "vue-router";
	import { MessageType } from "../expgenshared/MessageTypes";
	import { BotState } from "../expgenshared/BotState";
	import { BotActionResponse } from "../expgenshared/BotActionResponse";
	import { FarmState } from "../expgenshared/FarmSate";
	@Options({
		components: {
			LiveView,
			Status,
		},
		sockets: {
			getBotStatusResponse(botStats: BotState) {
				if (botStats.botId == this.botId) {
					this.botState = botStats;
				}
			},
			startBotResponse(response: BotState) {
				if (response.botId == this.botId) {
					this.botState = response;
				}
			},
			killBotResponse(response: BotActionResponse) {
				if (response.uuid == this.botId) {
					this.botState.farmState = FarmState.OFFLINE;
				}
			},
			stateChange(resp) {
				let [state, uuid]: [FarmState, string] = resp;
				if (uuid == this.botId) {
					this.botState.farmState = state;
				}
			},
		},
	})
	export default class Bot extends Vue {
		@Getter bot: (id: string) => CurrentBotsEntry;
		$socket!: any;
		sockets!: any;
		botState: BotState = null;
		botId: string | string[] = null;

		get currentBot() {
			let uuid = useRoute().params.id;

			if (!(typeof uuid == "string")) return null;
			return this.bot(uuid);
		}
		beforeUnmount() {
			//@ts-ignore
			this.sockets.unsubscribe(MessageType.BOT_STATUS_RESPONSE);
			this.sockets.unsubscribe(MessageType.START_BOT_RESPONSE);
			this.sockets.unsubscribe(MessageType.STATE_CHANGE);
			this.sockets.unsubscribe(MessageType.KILL_BOT_RESPONSE);
		}

		mounted() {
			this.botId = useRoute().params.id;
			this.$socket.emit(MessageType.BOT_STATUS, useRoute().params.id);
		}
	}
