import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Status = _resolveComponent("Status")!
  const _component_LiveView = _resolveComponent("LiveView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentBot != null && _ctx.botState)
      ? (_openBlock(), _createBlock(_component_Status, {
          key: 0,
          bot: _ctx.currentBot,
          botState: _ctx.botState
        }, null, 8, ["bot", "botState"]))
      : _createCommentVNode("", true),
    (_ctx.currentBot != null && _ctx.botState)
      ? (_openBlock(), _createBlock(_component_LiveView, {
          key: 1,
          bot: _ctx.currentBot,
          botState: _ctx.botState
        }, null, 8, ["bot", "botState"]))
      : _createCommentVNode("", true)
  ]))
}