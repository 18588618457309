import { withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveKey && _ctx.saveKey(...args)), ["enter"])),
    type: "text",
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.keyInput) = $event))
  }, null, 544)), [
    [_vModelText, _ctx.keyInput]
  ])
}