import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1be8ee99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 col-lg-9" }
const _hoisted_2 = { class: "card bg-light mb-3" }
const _hoisted_3 = { class: "card-header d-flex aligncenter" }
const _hoisted_4 = {
  key: 0,
  class: "live-overview"
}
const _hoisted_5 = {
  key: 1,
  class: "live-overview"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "card-body bg-black console" }
const _hoisted_8 = { class: "card-text" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showGraphic)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Live View"))
          : _createCommentVNode("", true),
        (!_ctx.showGraphic)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Console"))
          : _createCommentVNode("", true),
        (_ctx.showGraphic)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              style: {"margin-right":"5px"},
              class: "btn btn-sm btn-warning",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshLiveView && _ctx.refreshLiveView(...args)))
            }, " Refresh "))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "btn btn-sm btn-success",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showGraphic = !_ctx.showGraphic))
        }, " Switch View ")
      ]),
      (_ctx.bot)
        ? _withDirectives((_openBlock(), _createElementBlock("iframe", {
            key: 0,
            ref: "liveViewFrame",
            class: "embediframe",
            src: _ctx.viewUrl + ':' + _ctx.bot.viewerPort
          }, null, 8, _hoisted_6)), [
            [_vShow, _ctx.showGraphic]
          ])
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.botState.messages, (message) => {
            return (_openBlock(), _createElementBlock("span", {
              innerHTML: _ctx.convertMessage(message.ansiMessage),
              key: message.timestamp
            }, null, 8, _hoisted_9))
          }), 128))
        ])
      ], 512), [
        [_vShow, !_ctx.showGraphic]
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Type something...",
        class: "console-footer",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.consoleInput) = $event)),
        onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["enter"]))
      }, null, 544), [
        [_vShow, !_ctx.showGraphic],
        [_vModelText, _ctx.consoleInput]
      ])
    ])
  ]))
}