
	import { Options, Vue } from "vue-class-component";
	import { useRoute } from "vue-router";
	@Options({})
	export default class Login extends Vue {
		keyInput: string = "";
		saveKey() {
			localStorage.setItem("authKey", this.keyInput);
			window.location.href = "/";
		}
	}
