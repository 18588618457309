
	import { Options, prop, Vue } from "vue-class-component";
	import Time from "javascript-time-ago";

	//@ts-ignore
	import en from "javascript-time-ago/locale/en.json";
	Time.addLocale(en);
	class Props {
		time: number = prop({
			required: true,
		});
	}

	export default class TimeAgo extends Vue.with(Props) {
		interval: any = null;
		timeFormatter = new Time("en-US");
		displayTime = null;
		mounted() {
			this.displayTime = this.calcTime(this.time);
			this.interval = setInterval(() => {
				this.displayTime = this.calcTime(this.time);
			}, 2000);
		}

		beforeUnmount() {
			clearInterval(this.interval);
		}

		calcTime(time: number) {
			return this.timeFormatter.format(time);
		}
	}
